<template>
    <div class="fondo-degradado-recover"></div>
    <div class="contenedor-recover d-flex align-items-center justify-content-center flex-column">
        <h1 class="titulo-recover">¡Recupera tu Contraseña!</h1>
        <h2 class="subtitulo-recover">Te enviaremos un correo para hacer esto posible.</h2>
        <div class="cotainer-form">
            <FormCustom
                :objectForm="[
                    {
                        type: 'text',
                        label: 'Email',
                        name: 'email',
                    },
                    {
                        type: 'text',
                        label: 'Confirmar Email',
                        name: 'email2'
                    },
                ]"
                :actionSecButton="(data)=>this.sendResetPassword(data)"
                :textSecButton="'Recuperar'"
                typeStyle="column"
                :isOneButton="true"
            >
                <template v-slot:contentBeforeButton>
                    <div class="d-flex flex-row justify-content-center">
                        <router-link to="/register" class="link-recover">Registrarme</router-link>
                        <router-link to="/login" class="link-recover">Login</router-link>
                    </div>
                </template>
            </FormCustom>
        </div>
        <br>        
        <div class="error-login d-flex justify-content-center align-items-center" v-if="this.stateLogin.success === 'error'">
            <div><p>{{mensaje}}</p></div>
        </div>  
        <div class="correcto-login d-flex justify-content-center align-items-center" v-if="!this.isSesionActive &&this.stateLogin.success === 'ok'">
            <div><p>{{mensaje}}</p></div>
        </div>    
    </div>
</template>

<script>
    import {mapState, mapActions} from "vuex"
    import {swift} from '../../helpers/swift'
    import {quitarError, quitarHecho} from '../../helpers/quitarAvisosForm'
    import FormCustom from '../../components/FormCustom.vue'

    export default {
    components: { FormCustom },
        name: "Recover",
        data(){
            return{
                //control de respuestas de la api
                mensaje:'',
            }
        },
        
        methods:{
            ...mapActions([
                "sesionActive",
                "sesionInactive",
                "clearStateLogin",
                "recover",
                "badRegisterFront"
            ]),
            //enviar recuperacion de contrasena
            async sendResetPassword(data){
                //destructuring
                const {email,email2} = data

                //condicional de rellan los campos
                if(email === '' || email2 === ''){
                    //rellena los campos
                    this.badRegisterFront()
                    this.swift('Rellena los campos, por favor',1)
                }else{
                    if(email === email2){
                        await this.recover(email)

                        //control de errores
                        if(this.stateLogin.success === "ok"){
                            //correcto
                            this.swift('Correo enviado',2)
                        }else{
                            //usuario no registrado
                            this.swift('Usuario no registrado',1)
                        }
                    }else{
                        //los correos no coinciden
                        this.badRegisterFront()                            
                        this.swift('Los correos no coinciden', 1)
                    }
                }
            },
            //funcion encargada de quitar la caja de error
            quitarError,

            //funcion encargada de quitar la caja de correcto
            quitarHecho,

            //swift entre error y correcto
            swift,
        },
        mounted(){
            scrollTo(0,0)
            this.$forceUpdate()
        },
        computed:{
        ...mapState(["token", "server", "stateLogin", "isSesionActive"]),
        }
    }
</script>

<style scoped>
    .contenedor-recover{
        position: relative;
        height: 600px;
        width: 100%;
        overflow: hidden;
    }
    .cotainer-form{
        margin-top: 20px;
        width: 450px;
        height: auto;
    }
    .error-login{
        margin: 1.5em 0em 1.5em 0em;
        padding-top: 1em;
        width: 700px;
        background-color: rgb(128, 18, 22,0.75);
        color: white;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
    }
    .correcto-login{
        margin: 1.5em 0em 1.5em 0em;
        padding-top: 1em;
        width: 700px;
        background-color: rgba(14, 209, 69,0.5);
        color: white;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
    }
    .fondo-degradado-recover{
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        background: linear-gradient(290.11deg, rgba(17, 1, 51, 0.46) 0%, rgba(17, 1, 51, 0) 52.11%), linear-gradient(248.49deg, rgba(255, 255, 255, 0) 41.48%, #297F87 100.24%);
        height: 100vh;
    }
    .titulo-recover{
        font-family: Rubik;
        font-weight: bold;
        margin-top:90px ;
        font-size: 55px;
    }
    .subtitulo-recover{
        font-family: Rubik;
        margin-top:0px ;
        font-weight: bold;
        font-size: 25px;
    }
    .form-recover{
        width: 50%;
    }
    .label{
        font-family: Rubik;
        margin-top: 25px;   
        margin-left: 25%;
        font-weight:bold ;
    }
    .input{
        border-radius:10px;
        border: #6464646b 2px solid;
        width: 50%;
        margin-left: 25%;
        padding-left: 10px;
        padding-top: 3px;
        padding-bottom: 3px;
    }
    .button-google{
        border-radius:10px;
        margin-top: 45px;  
        width: 50%;
        margin-left: 25%;
        border: #6464646b 2px solid;
        height: 30px;
        background: white;
    }
    .link-recover{
        width: 150px;
        text-align: center;
        font-family: Rubik;
        font-weight:bold ;
        font-size: 16px;
        margin-top: 10px;
        color: #297F87;
        text-decoration: none;
    }
    .recupera-recover{
        margin-top: 7.5px;
        width: 100%;
        text-align: center;
        font-family: Rubik;
        font-weight:bold ;
        font-size: 16px;
    }
    .button-recover{
        width: 75%;
        height: 40px;
        border:none;
        border-radius:10px;
        background: #297F87;
        color: white;
        text-align: center;
        font-family: Rubik;
        font-size:20px ;
        margin-left: 12.5%;
        margin-top: 20px;
    }
</style>