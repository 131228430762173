export function swift (mensaje, caso, path) {
    if(caso === 1){
        this.mensaje = mensaje
        this.timeOut = setTimeout(() => {
            this.quitarError()
        }, 1500);
    }else{
        this.mensaje = mensaje
        this.timeOut = setTimeout(() => {
            this.quitarHecho(path)
        }, 1500);
    }
}