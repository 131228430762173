//funcion encargada de quitar la caja de error
export function quitarError(){
  this.mensaje = ''
  this.clearStateLogin() // reseteamos el resultado de la sesion                
}

//funcion encargada de quitar la caja de correcto
export function quitarHecho(path){
  this.mensaje = ''
  this.sesionActive() // cambiamos estado de sesión a activa
  this.clearStateLogin() // reseteamos el resultado del login                
  this.$router.push({path: path || '/app/profile'})
}